/* Google Font - Inter */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap')

/* Default CSS */
html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #21295c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7a7f9d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #717cc1;
}
